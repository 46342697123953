import _ from 'lodash';

import { EVENTS as BIOTOPE_REF_MAP_STORE_EVENTS } from '../../stores/biotope-ref-map.store';
import angular from 'angular';

require('./select-biotope-ref-buttons.scss');

export default SelectBiotopeRefButtonsDirective;

// @ngInject
function SelectBiotopeRefButtonsDirective() {
  return {
    restrict: 'E',
    scope: {
      initialValueIdProvider: '&initialValueId',
      initialValueKeyProvider: '&initialValueKey',
      placeholder: '@',
      name: '@',
      onChangeFn: '&onChange',
      canEditProvider: '&canEdit',
      requiredProvider: '&isRequired',
      forceClearProvider: '&forceClear',
      refTableName: '@'
    },
    bindToController: true,
    controller: SelectBiotopeRefButtonsController,
    controllerAs: 'selectBiotopeRefButtonsCtrl',
    template: require('./select-biotope-ref-buttons.html'),
    replace: true
  };
}

// @ngInject
function SelectBiotopeRefButtonsController($scope, BiotopeRefMapStore, BiotopeRefMapService) {
  var selectBiotopeRefButtonsCtrl = this;

  selectBiotopeRefButtonsCtrl.onChange = onChange;
  selectBiotopeRefButtonsCtrl.currentValue = null;
  selectBiotopeRefButtonsCtrl.deletedValue = null;

  $scope.$on(BIOTOPE_REF_MAP_STORE_EVENTS.BIOTOPE_REF_MAP_RESULTS_STATE_CHANGED, reloadResults);
  selectBiotopeRefButtonsCtrl.availableValues = [];
  selectBiotopeRefButtonsCtrl.state = {};
  BiotopeRefMapService.load(selectBiotopeRefButtonsCtrl.refTableName);

  $scope.$watch(selectBiotopeRefButtonsCtrl.canEditProvider, (canEdit) => {
    selectBiotopeRefButtonsCtrl.canEdit = canEdit;
  });
  $scope.$watch(selectBiotopeRefButtonsCtrl.requiredProvider, (required) => {
    selectBiotopeRefButtonsCtrl.required = required;
  });
  $scope.$watch(selectBiotopeRefButtonsCtrl.forceClearProvider, (forceClear) => {
    selectBiotopeRefButtonsCtrl.forceClear = forceClear;
  });
  $scope.$watch(selectBiotopeRefButtonsCtrl.initialValueIdProvider, (initialValueId) => {
    if (!initialValueId && !selectBiotopeRefButtonsCtrl.forceClear) {
      return;
    }

    selectBiotopeRefButtonsCtrl.initialValueId = initialValueId;
    updateInitialValue();
  });
  $scope.$watch(selectBiotopeRefButtonsCtrl.initialValueKeyProvider, (initialValueKey) => {
    if ((!initialValueKey || selectBiotopeRefButtonsCtrl.initialValueId) && !selectBiotopeRefButtonsCtrl.forceClear) {
      return;
    }

    selectBiotopeRefButtonsCtrl.initialValueKey = initialValueKey;
    updateInitialValue();
  });

  function reloadResults(event, tableChanged) {
    if (tableChanged !== selectBiotopeRefButtonsCtrl.refTableName) {
      return;
    }

    selectBiotopeRefButtonsCtrl.state = BiotopeRefMapStore.getState(selectBiotopeRefButtonsCtrl.refTableName);

    if (!selectBiotopeRefButtonsCtrl.state.results) {
      return;
    }

    selectBiotopeRefButtonsCtrl.availableValues = formatResults(_.reject(selectBiotopeRefButtonsCtrl.state.results, 'deleted'));
    updateInitialValue();
  }

  function formatResults(values) {
    if (!_.some(values, 'header')) {
      return values;
    }

    var res = {};
    var header = '-';
    var options = [];

    for (let i = 0; i < values.length; i++) {
      if (!values[i].header) {
        options.push(values[i]);
        continue;
      }

      if (options.length > 0) {
        res[header] = options;
      }
      options = [];
      header = values[i].label;
    }
    res[header] = options;
    return res;
  }

  function updateInitialValue() {
    if (!selectBiotopeRefButtonsCtrl.forceClear &&
        (!(selectBiotopeRefButtonsCtrl.initialValueId || selectBiotopeRefButtonsCtrl.initialValueKey) || !selectBiotopeRefButtonsCtrl.state.loaded)) {
      return;
    }

    let initialValue = null;
    if (selectBiotopeRefButtonsCtrl.initialValueId) {
      // On cherche à partir de initialValueId
      initialValue =  _.find(selectBiotopeRefButtonsCtrl.state.results, {id: selectBiotopeRefButtonsCtrl.initialValueId});
    }
    if (!initialValue && selectBiotopeRefButtonsCtrl.initialValueKey) {
      // On n'a pas trouvé initialValueId, on cherche à partir de initialValueKey
      initialValue = _.find(selectBiotopeRefButtonsCtrl.state.results, {key: selectBiotopeRefButtonsCtrl.initialValueKey});
    }
    if (!initialValue && !selectBiotopeRefButtonsCtrl.forceClear) {
      // On n'a pas trouvé de valeur à initialiser à partir de initialValueId ni de initialValueKey
      return;
    }

    onChange(initialValue, true);
    if (initialValue && initialValue.deleted) {
      selectBiotopeRefButtonsCtrl.deletedValue = initialValue;
      return;
    }

    selectBiotopeRefButtonsCtrl.currentValue = initialValue;
    selectBiotopeRefButtonsCtrl.currentValueId = initialValue ? initialValue.id : null;
  }

  function onChange(newValue, force) {
    if (newValue == selectBiotopeRefButtonsCtrl.currentValue && !force) {
      selectBiotopeRefButtonsCtrl.currentValueId = null;
      selectBiotopeRefButtonsCtrl.currentValue = null;
      selectBiotopeRefButtonsCtrl.onChangeFn() && selectBiotopeRefButtonsCtrl.onChangeFn()(null);
    } else {
      selectBiotopeRefButtonsCtrl.currentValueId = newValue ? newValue.id : null;
      selectBiotopeRefButtonsCtrl.onChangeFn() && selectBiotopeRefButtonsCtrl.onChangeFn()(newValue);
    }
  }
}
