import _ from 'lodash';

import { EVENTS as BIOTOPE_REF_MAP_STORE_EVENTS } from '../../stores/biotope-ref-map.store';

require('./select-biotope-ref-buttons-table.scss');

export default SelectBiotopeRefButtonsTableDirective;

// @ngInject
function SelectBiotopeRefButtonsTableDirective() {
  return {
    restrict: 'E',
    scope: {
      initialValueIdProvider: '&initialValueId',
      initialValueKeyProvider: '&initialValueKey',
      placeholder: '@',
      name: '@',
      onChangeFn: '&onChange',
      findByLevelFn:  '&findByLevel',
      canEditProvider: '&canEdit',
      requiredProvider: '&isRequired',
      refTableName: '@'
    },
    bindToController: true,
    controller: SelectBiotopeRefButtonsTableController,
    controllerAs: 'selectBiotopeRefButtonsTableCtrl',
    template: require('./select-biotope-ref-buttons-table.html'),
    replace: true
  };
}

// @ngInject
function SelectBiotopeRefButtonsTableController($scope, BiotopeRefMapStore, BiotopeRefMapService, LxNotificationService) {
  var selectBiotopeRefButtonsTableCtrl = this;

  selectBiotopeRefButtonsTableCtrl.onChange = onChange;
  selectBiotopeRefButtonsTableCtrl.findByLevel = findByLevel;
  selectBiotopeRefButtonsTableCtrl.currentValue = null;
  selectBiotopeRefButtonsTableCtrl.deletedValue = null;

  $scope.$on(BIOTOPE_REF_MAP_STORE_EVENTS.BIOTOPE_REF_MAP_RESULTS_STATE_CHANGED, reloadResults);
  selectBiotopeRefButtonsTableCtrl.availableValues = [];
  selectBiotopeRefButtonsTableCtrl.state = {};
  BiotopeRefMapService.load(selectBiotopeRefButtonsTableCtrl.refTableName);

  $scope.$watch(selectBiotopeRefButtonsTableCtrl.canEditProvider, (canEdit) => {
    selectBiotopeRefButtonsTableCtrl.canEdit = canEdit;
  });
  $scope.$watch(selectBiotopeRefButtonsTableCtrl.requiredProvider, (required) => {
    selectBiotopeRefButtonsTableCtrl.required = required;
  });
  $scope.$watch(selectBiotopeRefButtonsTableCtrl.initialValueIdProvider, (initialValueId) => {
    if (!initialValueId) {
      return;
    }

    selectBiotopeRefButtonsTableCtrl.initialValueId = initialValueId;
    updateInitialValue();
  });
  $scope.$watch(selectBiotopeRefButtonsTableCtrl.initialValueKeyProvider, (initialValueKey) => {
    if (!initialValueKey || selectBiotopeRefButtonsTableCtrl.initialValueId) {
      return;
    }

    selectBiotopeRefButtonsTableCtrl.initialValueKey = initialValueKey;
    updateInitialValue();
  });

  function reloadResults(event, tableChanged) {
    if (tableChanged !== selectBiotopeRefButtonsTableCtrl.refTableName) {
      return;
    }

    selectBiotopeRefButtonsTableCtrl.state = BiotopeRefMapStore.getState(selectBiotopeRefButtonsTableCtrl.refTableName);

    if (!selectBiotopeRefButtonsTableCtrl.state.results) {
      return;
    }

    selectBiotopeRefButtonsTableCtrl.availableValues = _.reject(selectBiotopeRefButtonsTableCtrl.state.results, 'deleted');
    updateInitialValue();
  }

  function updateInitialValue() {
    if (!(selectBiotopeRefButtonsTableCtrl.initialValueId || selectBiotopeRefButtonsTableCtrl.initialValueKey) ||
    !selectBiotopeRefButtonsTableCtrl.state.loaded) {
      return;
    }

    let initialValue = null;
    if (selectBiotopeRefButtonsTableCtrl.initialValueId) {
      // On cherche à partir de initialValueId
      initialValue =  _.find(selectBiotopeRefButtonsTableCtrl.state.results, {id: selectBiotopeRefButtonsTableCtrl.initialValueId});
    }
    if (!initialValue && selectBiotopeRefButtonsTableCtrl.initialValueKey) {
      // On n'a pas trouvé initialValueId, on cherche à partir de initialValueKey
      initialValue = _.find(selectBiotopeRefButtonsTableCtrl.state.results, {key: selectBiotopeRefButtonsTableCtrl.initialValueKey});
    }
    if (!initialValue) {
      // On n'a pas trouvé de valeur à initialiser à partir de initialValueId ni de initialValueKey
      return;
    }
    onChange(initialValue, true);
    if (initialValue.deleted) {
      selectBiotopeRefButtonsTableCtrl.deletedValue = initialValue;
      return;
    }

    selectBiotopeRefButtonsTableCtrl.currentValue = initialValue;
    selectBiotopeRefButtonsTableCtrl.currentValueId = initialValue.id;
  }

  function findByLevel(levelValue) {
    return _.find(selectBiotopeRefButtonsTableCtrl.state.results, {level: levelValue});
  }

  function onChange(newValue, force) {
    if (newValue == selectBiotopeRefButtonsTableCtrl.currentValue && !force) {
      selectBiotopeRefButtonsTableCtrl.currentValueId = null;
      selectBiotopeRefButtonsTableCtrl.currentValue = null;
      selectBiotopeRefButtonsTableCtrl.onChangeFn() && selectBiotopeRefButtonsTableCtrl.onChangeFn()(null);
    } else {
      selectBiotopeRefButtonsTableCtrl.currentValueId = newValue ? newValue.id : null;
      selectBiotopeRefButtonsTableCtrl.onChangeFn() && selectBiotopeRefButtonsTableCtrl.onChangeFn()(newValue);
    }
  }
}
