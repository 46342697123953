import _ from 'lodash';
import moment from 'moment-timezone';

import { ACCURACY, STATUS } from '../../model';

require('./field-record-details-main-tab.scss');

export default FieldRecordDetailsMainTabDirective;

// @ngInject
function FieldRecordDetailsMainTabDirective() {
  return {
    restrict: 'E',
    scope: {
      studyId: '@',
      initialFieldRecordProvider: '&initialFieldRecord',
      timezone: '@',
      protocol: '@',
      onChangeFn: '&onChange',
      formProvider: '&form',
      canEditProvider: '&canEdit',
      canSubmitFn: '&canSubmit',
      isNewProvider: '&isNew',
      onDeleteFieldRecordFn: '&onDeleteFieldRecord',
      onCreateAndContinueClickedFn: '&onCreateAndContinueClicked',
      isAutoLocatedValidFn: '&isAutoLocatedValid',
      isAutoLocatedErrorFn: '&isAutoLocatedError',
      locationPrecisionProvider: '&locationPrecision'
    },
    bindToController: true,
    controller: FieldRecordDetailsMainTabController,
    controllerAs: 'fieldRecordDetailsMainTabCtrl',
    template: require('./field-record-details-main-tab.html'),
    replace: true,
  };
}

// @ngInject
function FieldRecordDetailsMainTabController($scope, $timeout) {
  var fieldRecordDetailsMainTabCtrl = this;

  fieldRecordDetailsMainTabCtrl.accuracyValues = ACCURACY.VALUES;
  fieldRecordDetailsMainTabCtrl.statusValues = STATUS.VALUES;

  fieldRecordDetailsMainTabCtrl.onChange = onChange;
  fieldRecordDetailsMainTabCtrl.onReferentielChanged = onReferentielChanged;
  fieldRecordDetailsMainTabCtrl.onFilterChanged = onFilterChanged;
  fieldRecordDetailsMainTabCtrl.onTaxonChanged = onTaxonChanged;
  fieldRecordDetailsMainTabCtrl.onDeleteFieldRecord = onDeleteFieldRecord;
  fieldRecordDetailsMainTabCtrl.onAccuracySelected = onAccuracySelected;
  fieldRecordDetailsMainTabCtrl.canSubmit = canSubmit;
  fieldRecordDetailsMainTabCtrl.onCreateAndContinueClicked = onCreateAndContinueClicked;
  fieldRecordDetailsMainTabCtrl.isWebClient = isWebClient;
  fieldRecordDetailsMainTabCtrl.isAutoLocatedValid = isAutoLocatedValid;
  fieldRecordDetailsMainTabCtrl.isAutoLocatedError = isAutoLocatedError;
  fieldRecordDetailsMainTabCtrl.locationPrecision = locationPrecision;

  $scope.$watch(fieldRecordDetailsMainTabCtrl.initialFieldRecordProvider, (initialFieldRecord) => {
    if (!initialFieldRecord) {
      return;
    }

    fieldRecordDetailsMainTabCtrl.initialFieldRecord = initialFieldRecord;
    //txRefHistory is the json send by the field-record ws
    //this field does not exist in the local db, the local field is txRefFiler

    if(initialFieldRecord.txRefHistory){

      fieldRecordDetailsMainTabCtrl.initialFieldRecord =
      Object.assign({}, fieldRecordDetailsMainTabCtrl.initialFieldRecord, {txRefFilter:initialFieldRecord.txRefHistory.tx_ref_filter});
    }
    fieldRecordDetailsMainTabCtrl.txGroups = [initialFieldRecord.txGroup];

    var initialTxTime = initialFieldRecord.txTime || new Date().getTime() / 1000;
    var currentFormattedTime = moment(initialTxTime * 1000)
      .tz(fieldRecordDetailsMainTabCtrl.timezone)
      .format('HH:mm');
    fieldRecordDetailsMainTabCtrl.fieldRecord = _.defaults(
      {
        time: currentFormattedTime,
        date: new Date(initialTxTime * 1000),
        txSure: initialFieldRecord.isTxSure === undefined ? true : initialFieldRecord.isTxSure,
        txPresent: initialFieldRecord.isTxPresent === undefined ? true : initialFieldRecord.isTxPresent,
        ghost: initialFieldRecord.ghost === undefined ? false : initialFieldRecord.ghost,
        fieldSurveyId: initialFieldRecord.fieldSurveyId,
        status: initialFieldRecord.status || STATUS.DEFAULT,
        txHeadcount:
          initialFieldRecord.txHeadcount ||
          (_.includes(['PE', 'LS', 'RI'], fieldRecordDetailsMainTabCtrl.protocol) ? 1 : null),
        txHeadcountAccuracy: initialFieldRecord.txHeadcountAccuracy || ACCURACY.DEFAULT,
      },
      initialFieldRecord
    );

    onChange();
  });
  $scope.$watch(fieldRecordDetailsMainTabCtrl.canEditProvider, (canEdit) => {
    fieldRecordDetailsMainTabCtrl.canEdit = canEdit;
  });
  $scope.$watch(fieldRecordDetailsMainTabCtrl.formProvider, (form) => {
    fieldRecordDetailsMainTabCtrl.form = form;
  });
  $scope.$watch(fieldRecordDetailsMainTabCtrl.isNewProvider, (isNew) => {
    fieldRecordDetailsMainTabCtrl.isNew = isNew;
  });

  function onChange() {

    // utilisation du timeout pour attendre que les composants du formulaire soient bien initialisés avant d'évaluer isFormValid()
    $timeout(() => {
      var time;
      if (fieldRecordDetailsMainTabCtrl.fieldRecord.time) {
        time = moment(fieldRecordDetailsMainTabCtrl.fieldRecord.time, 'HH:mm');
      } else {
        time = null;
      }
      var txTime =
        moment(fieldRecordDetailsMainTabCtrl.fieldRecord.date)
          .tz(fieldRecordDetailsMainTabCtrl.timezone)
          .hours(time ? time.hours() : 0)
          .minutes(time ? time.minutes() : 0)
          .toDate()
          .getTime() / 1000;
      fieldRecordDetailsMainTabCtrl.onChangeFn()({
        data: {
          description: fieldRecordDetailsMainTabCtrl.fieldRecord.description,
          txTime,
          txSure: fieldRecordDetailsMainTabCtrl.fieldRecord.txSure,
          txPresent: fieldRecordDetailsMainTabCtrl.fieldRecord.txPresent,
          txRefType: fieldRecordDetailsMainTabCtrl.txRef && fieldRecordDetailsMainTabCtrl.txRef.ref,
          txRefVersion: fieldRecordDetailsMainTabCtrl.txRef && fieldRecordDetailsMainTabCtrl.txRef.version,
          txRefFilter: fieldRecordDetailsMainTabCtrl.txFilter && fieldRecordDetailsMainTabCtrl.txFilter.key,
          txGroupKey: fieldRecordDetailsMainTabCtrl.taxon && fieldRecordDetailsMainTabCtrl.taxon.txGroup_key,
          txKey: fieldRecordDetailsMainTabCtrl.taxon && fieldRecordDetailsMainTabCtrl.taxon.key,
          txName: fieldRecordDetailsMainTabCtrl.taxon && fieldRecordDetailsMainTabCtrl.taxon.name,
          txVernacularName:
            fieldRecordDetailsMainTabCtrl.taxon && (_.head(fieldRecordDetailsMainTabCtrl.taxon.vernacularName) || null),
          txHeadcount: fieldRecordDetailsMainTabCtrl.fieldRecord.txHeadcount,
          txHeadcountAccuracy: fieldRecordDetailsMainTabCtrl.fieldRecord.txHeadcountAccuracy,
          status: fieldRecordDetailsMainTabCtrl.fieldRecord.status,
          ghost: fieldRecordDetailsMainTabCtrl.fieldRecord.ghost,
          fieldSurveyId: fieldRecordDetailsMainTabCtrl.isNew
            ? fieldRecordDetailsMainTabCtrl.fieldRecord.fieldSurveyId || null
            : fieldRecordDetailsMainTabCtrl.fieldRecord.fieldSurvey &&
              fieldRecordDetailsMainTabCtrl.fieldRecord.fieldSurvey.id,
        },
        formValid: isFormValid(),
      });
    });
  }

  function onReferentielChanged(referentiel) {
    fieldRecordDetailsMainTabCtrl.txRef = referentiel;

    if (!referentiel) {
      onTaxonChanged(null);
    }
    onChange();
  }
  function onFilterChanged(filter) {
    fieldRecordDetailsMainTabCtrl.txFilter = filter;

    if (!filter) {
      onTaxonChanged(null);
    }

    onChange();
  }

  function onTaxonChanged(taxon) {
    fieldRecordDetailsMainTabCtrl.taxon = taxon;
    onChange();
  }

  function onAccuracySelected() {
    if (fieldRecordDetailsMainTabCtrl.fieldRecord.txHeadcountAccuracy == fieldRecordDetailsMainTabCtrl.oldAccuracy) {
      fieldRecordDetailsMainTabCtrl.oldAccuracy = null;
      fieldRecordDetailsMainTabCtrl.fieldRecord.txHeadcountAccuracy = null;
    } else {
      fieldRecordDetailsMainTabCtrl.oldAccuracy = fieldRecordDetailsMainTabCtrl.fieldRecord.txHeadcountAccuracy;
    }
    onChange();
  }

  function canSubmit() {
    return fieldRecordDetailsMainTabCtrl.canSubmitFn() && fieldRecordDetailsMainTabCtrl.canSubmitFn()();
  }

  function isFormValid() {
    return (
      fieldRecordDetailsMainTabCtrl.form['fieldRecord.txTime.date'] &&
      fieldRecordDetailsMainTabCtrl.form['fieldRecord.txTime.date'].$valid &&
      fieldRecordDetailsMainTabCtrl.form['fieldRecord.txTime.time'].$valid &&
      fieldRecordDetailsMainTabCtrl.form['fieldRecord.txTime.time'].$valid &&
      fieldRecordDetailsMainTabCtrl.form['fieldRecord.taxon'] &&
      fieldRecordDetailsMainTabCtrl.form['fieldRecord.taxon'].$valid
    );
  }

  function onDeleteFieldRecord() {
    return (
      fieldRecordDetailsMainTabCtrl.onDeleteFieldRecordFn() && fieldRecordDetailsMainTabCtrl.onDeleteFieldRecordFn()()
    );
  }

  function onCreateAndContinueClicked() {
    return (
      fieldRecordDetailsMainTabCtrl.onCreateAndContinueClickedFn() &&
      fieldRecordDetailsMainTabCtrl.onCreateAndContinueClickedFn()()
    );
  }

  function isWebClient() {
    return !IS_CORDOVA;
  }

  function locationPrecision() {
    return fieldRecordDetailsMainTabCtrl.locationPrecisionProvider();
  }

  function isAutoLocatedValid() {
    return fieldRecordDetailsMainTabCtrl.isAutoLocatedValidFn() &&
    fieldRecordDetailsMainTabCtrl.isAutoLocatedValidFn()();
  }

  function isAutoLocatedError() {
    return fieldRecordDetailsMainTabCtrl.isAutoLocatedErrorFn() &&
    fieldRecordDetailsMainTabCtrl.isAutoLocatedErrorFn()();
  }
}
