import angular from 'angular';

import selectBiotopeRefComponent from './components/select-biotope-ref/select-biotope-ref.component.js';
import selectBiotopeRefStComponent from './components/select-biotope-ref-st/select-biotope-ref-st.component.js';
import selectBiotopeRefButtonsComponent from './components/select-biotope-ref-buttons/select-biotope-ref-buttons.component.js';
import biotopeRefButtonComponent from './components/biotope-ref-button/biotope-ref-button.component.js';
import selectBiotopeRefButtonsTableComponent from './components/select-biotope-ref-buttons-table/select-biotope-ref-buttons-table.component.js';
import biotopeRefMapService from './services/biotope-ref-map.service';
import biotopeRefMapStore from './stores/biotope-ref-map.store';
import remoteBiotopeRefRepository from './repositories/biotope-ref.remote.repository';
import localBiotopeRefRepository from './repositories/biotope-ref.local.repository';
import biotopeRefConfiguration from './configuration';

const moduleName = 'shu-biotope-ref';

angular.module(moduleName, ['lumx'])
    .directive('shuSelectBiotopeRef', selectBiotopeRefComponent)
    .directive('shuSelectBiotopeRefSt', selectBiotopeRefStComponent)    
    .directive('shuSelectBiotopeRefButtons', selectBiotopeRefButtonsComponent)
    .directive('shuBiotopeRefButton', biotopeRefButtonComponent)
    .directive('shuSelectBiotopeRefButtonsTable', selectBiotopeRefButtonsTableComponent)
    .factory('BiotopeRefRepository', IS_CORDOVA ? localBiotopeRefRepository : remoteBiotopeRefRepository)
    .factory('BiotopeRefMapService', biotopeRefMapService)
    .factory('BiotopeRefMapStore', biotopeRefMapStore)
    .constant('BiotopeRefConfiguration', biotopeRefConfiguration);

export default {moduleName};
