import _ from 'lodash';

import { EVENTS as BIOTOPE_REF_MAP_STORE_EVENTS } from '../../stores/biotope-ref-map.store';

require('./select-biotope-ref.scss');

export default SelectBiotopeRefDirective;

// @ngInject
function SelectBiotopeRefDirective() {
  return {
    restrict: 'E',
    scope: {
      initialValueIdProvider: '&initialValueId',
      initialValueKeyProvider: '&initialValueKey',
      placeholder: '@',
      name: '@',
      onChangeFn: '&onChange',
      canEditProvider: '&canEdit',
      requiredProvider: '&required',
      forceClearProvider: '&forceClear',
      refTableName: '@'
    },
    bindToController: true,
    controller: SelectBiotopeRefController,
    controllerAs: 'selectBiotopeRefCtrl',
    template: require('./select-biotope-ref.html'),
    replace: true
  };
}

// @ngInject
function SelectBiotopeRefController($scope, BiotopeRefMapStore, BiotopeRefMapService) {
  var selectBiotopeRefCtrl = this;

  selectBiotopeRefCtrl.onChange = onChange;
  selectBiotopeRefCtrl.currentValue = null;
  selectBiotopeRefCtrl.deletedValue = null;

  $scope.$on(BIOTOPE_REF_MAP_STORE_EVENTS.BIOTOPE_REF_MAP_RESULTS_STATE_CHANGED, reloadResults);
  selectBiotopeRefCtrl.availableValues = [];
  selectBiotopeRefCtrl.state = {};
  BiotopeRefMapService.load(selectBiotopeRefCtrl.refTableName);

  $scope.$watch(selectBiotopeRefCtrl.forceClearProvider, (forceClear) => {
      selectBiotopeRefCtrl.forceClear = forceClear;
  });
  $scope.$watch(selectBiotopeRefCtrl.canEditProvider, (canEdit) => {
    selectBiotopeRefCtrl.canEdit = canEdit;
  });
  $scope.$watch(selectBiotopeRefCtrl.requiredProvider, (required) => {
    selectBiotopeRefCtrl.required = required;
  });
  $scope.$watch(selectBiotopeRefCtrl.initialValueIdProvider, (initialValueId) => {
    if (!initialValueId && !selectBiotopeRefCtrl.forceClear) {
      return;
    }

    selectBiotopeRefCtrl.initialValueId = initialValueId;
    updateInitialValue();
  });
  $scope.$watch(selectBiotopeRefCtrl.initialValueKeyProvider, (initialValueKey) => {
    if (!initialValueKey && !selectBiotopeRefCtrl.forceClear) {
      return;
    }

    selectBiotopeRefCtrl.initialValueKey = initialValueKey;
    updateInitialValue();
  });

  function reloadResults(event, tableChanged) {
    if (tableChanged !== selectBiotopeRefCtrl.refTableName) {
      return;
    }

    selectBiotopeRefCtrl.state = BiotopeRefMapStore.getState(selectBiotopeRefCtrl.refTableName);

    if (!selectBiotopeRefCtrl.state.results) {
      return;
    }

    selectBiotopeRefCtrl.availableValues = formatResults(_.reject(selectBiotopeRefCtrl.state.results, 'deleted'));
    updateInitialValue();
  }

  function formatResults(values) {
    if (!_.some(values, 'header')) {
      return values;
    }

    var res = {};
    var header = '-';
    var options = [];

    for (let i = 0; i < values.length; i++) {
      if (!values[i].header) {
        options.push(values[i]);
        continue;
      }

      if (options.length > 0) {
        res[header] = options;
      }
      options = [];
      header = values[i].label;
    }
    res[header] = options;
    return res;
  }

  function updateInitialValue() {
    if (!selectBiotopeRefCtrl.forceClear &&
        (!(selectBiotopeRefCtrl.initialValueId || selectBiotopeRefCtrl.initialValueKey) || !selectBiotopeRefCtrl.state.loaded)) {
      return;
    }
    let initialValue = null;
    if (selectBiotopeRefCtrl.initialValueId) {
      // On cherche à partir de initialValueId
      initialValue =  _.find(selectBiotopeRefCtrl.state.results, {id: selectBiotopeRefCtrl.initialValueId});
    }
    if (!initialValue && selectBiotopeRefCtrl.initialValueKey) {
      // On n'a pas trouvé initialValueId, on cherche à partir de initialValueKey
      initialValue = _.find(selectBiotopeRefCtrl.state.results, {key: selectBiotopeRefCtrl.initialValueKey});
    }
    if (!initialValue && !selectBiotopeRefCtrl.forceClear) {
      // On n'a pas trouvé de valeur à initialiser à partir de initialValueId ni de initialValueKey
      return;
    }

    onChange(initialValue, true);
    if (initialValue && initialValue.deleted) {
      selectBiotopeRefCtrl.deletedValue = initialValue;
      return;
    }

    selectBiotopeRefCtrl.currentValue = initialValue;
    selectBiotopeRefCtrl.currentValueId = initialValue ? initialValue.id : null;
  }

  function onChange(newValue, force) {
    if (newValue == selectBiotopeRefCtrl.currentValue && !force) {
      selectBiotopeRefCtrl.currentValueId = null;
      selectBiotopeRefCtrl.currentValue = null;
      selectBiotopeRefCtrl.onChangeFn() && selectBiotopeRefCtrl.onChangeFn()(null);
    } else {
      selectBiotopeRefCtrl.currentValueId = newValue ? newValue.id : null;
      selectBiotopeRefCtrl.onChangeFn() && selectBiotopeRefCtrl.onChangeFn()(newValue);
    }
  }
}
