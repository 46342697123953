import _ from 'lodash';

import { EVENTS as STUDY_STORE_EVENTS } from '../../../study/stores/study-details.store';
import { EVENTS as FIELD_RECORD_DETAILS_STORE_EVENTS } from '../../stores/field-record-details.store';
import { EVENTS as FIELD_RECORD_EXTRA_TABLES_STORE_EVENTS } from '../../stores/field-record-extra-tables.store';
import { EVENTS as FIELD_SURVEY_DETAILS_STORE_EVENTS } from '../../../field-survey/stores/field-survey-details.store';
import { EVENTS as STUDY_EXPERT_LAYER_STORE_EVENTS } from '../../../study/stores/study-expert-layer.store';

import { ACTIVATE_FIELD_RECORD_MAP_TAB } from '../../../protocol/model';
import { CREATION_MODE } from '../../model';

require('./field-record-details-container.scss');

export default FieldRecordDetailsContainerDirective;

// @ngInject
function FieldRecordDetailsContainerDirective() {
  return {
    restrict: 'E',
    scope: {
      studyId: '@',
      fieldSurveyId: '@',
      txKey: '@',
      txRefKey: '@',
      txRefFilter: '@',
      txRefVersion: '@',
      txGroup: '@',
      fieldRecordId: '@',
      txHeadcountStr: '@txHeadcount',
      txHeadcountAccuracy: '@',
      fromState: '@',
      fromParamsProvider: '&fromParams',
      extraTablesProvider: '&extraTables',
      creationMode: '@',
    },
    bindToController: true,
    controller: FieldRecordDetailsContainerController,
    controllerAs: 'fieldRecordDetailsContainerCtrl',
    template: require('./field-record-details-container.html'),
    replace: true,
  };
}

// @ngInject
function FieldRecordDetailsContainerController(
  $scope,
  $filter,
  $state,
  LxNotificationService,
  StudyDetailsStore,
  FieldRecordDetailsStore,
  FieldRecordExtraTablesStore,
  FieldSurveyDetailsStore,
  FieldRecordDetailsService,
  StudyDetailsService,
  FieldRecordExtraTablesService,
  FieldSurveyDetailsService,
  HeaderService,
  MediaService,
  StudyMapService,
  StudyExpertLayerService,
  StudyExpertLayerStore,
  FieldRecordRepository
) {
  var fieldRecordDetailsContainerCtrl = this;

  fieldRecordDetailsContainerCtrl.mainTabFormData = null;
  fieldRecordDetailsContainerCtrl.mapTabFormData = null;
  fieldRecordDetailsContainerCtrl.headerUpdated = false;
  fieldRecordDetailsContainerCtrl.extraTabsFormData = {};
  fieldRecordDetailsContainerCtrl.lockedTabs = [];
  //TODO get error from the tab itself ?
  fieldRecordDetailsContainerCtrl.lockedTabErrors = {
    map: "L'observation ne peut être créée car la localisation n'a pas été validée dans l'onglet carte.",
  };

  fieldRecordDetailsContainerCtrl.isWebClient = isWebClient;
  fieldRecordDetailsContainerCtrl.isLoading = isLoading;
  fieldRecordDetailsContainerCtrl.isLoaded = isLoaded;
  fieldRecordDetailsContainerCtrl.isNew = isNew;
  fieldRecordDetailsContainerCtrl.canEdit = canEdit;
  fieldRecordDetailsContainerCtrl.canEditMap = canEditMap;
  fieldRecordDetailsContainerCtrl.canSubmit = canSubmit;
  fieldRecordDetailsContainerCtrl.hasFieldSurvey = hasFieldSurvey;
  fieldRecordDetailsContainerCtrl.useExtraTable = useExtraTable;
  fieldRecordDetailsContainerCtrl.getExtraTable = getExtraTable;
  fieldRecordDetailsContainerCtrl.onMainTabChanged = onMainTabChanged;
  fieldRecordDetailsContainerCtrl.onMediaTabChanged = onMediaTabChanged;
  fieldRecordDetailsContainerCtrl.onSubmit = onSubmit;
  fieldRecordDetailsContainerCtrl.onUploadMedia = onUploadMedia;
  fieldRecordDetailsContainerCtrl.isExtraTableValid = isExtraTableValid;
  fieldRecordDetailsContainerCtrl.onDeleteFieldRecord = onDeleteFieldRecord;
  fieldRecordDetailsContainerCtrl.onPositionChange = onPositionChange;
  fieldRecordDetailsContainerCtrl.onETTabChanged = onETTabChanged;
  fieldRecordDetailsContainerCtrl.onCreateAndContinueClicked = onCreateAndContinueClicked;
  fieldRecordDetailsContainerCtrl.onLocalLayerChanged = onLocalLayerChanged;
  fieldRecordDetailsContainerCtrl.onLockedTabChange = onLockedTabChange;
  fieldRecordDetailsContainerCtrl.isAutoLocatedValid = isAutoLocatedValid;
  fieldRecordDetailsContainerCtrl.isAutoLocatedError = isAutoLocatedError;

  $scope.$on(STUDY_STORE_EVENTS.STUDY_DETAILS_STATE_CHANGED, reloadStudyState);
  $scope.$on(FIELD_RECORD_DETAILS_STORE_EVENTS.FIELD_RECORD_DETAILS_STATE_CHANGED, reloadFieldRecordState);
  $scope.$on(FIELD_RECORD_EXTRA_TABLES_STORE_EVENTS.FIELD_RECORD_EXTRA_TABLES_STATE_CHANGED, reloadExtraTablesState);
  $scope.$on(FIELD_SURVEY_DETAILS_STORE_EVENTS.FIELD_SURVEY_DETAILS_STATE_CHANGED, reloadFieldSurveyState);
  $scope.$on(STUDY_EXPERT_LAYER_STORE_EVENTS.STUDY_EXPERT_LAYER_STATE_CHANGED, reloadLayers);

  StudyDetailsService.load(fieldRecordDetailsContainerCtrl.studyId);
  StudyExpertLayerService.load(fieldRecordDetailsContainerCtrl.studyId);

  if (fieldRecordDetailsContainerCtrl.fieldSurveyId) {
    FieldSurveyDetailsService.load(fieldRecordDetailsContainerCtrl.fieldSurveyId, true);
  }

  if (isNew()) {
    if (fieldRecordDetailsContainerCtrl.fieldSurveyId) {
      FieldRecordExtraTablesService.load(
        fieldRecordDetailsContainerCtrl.fieldSurveyId,
        fieldRecordDetailsContainerCtrl.txGroup
      );
    }

    /*if(IS_CORDOVA){
          SettingsOppService.getShuSettings('opportunistObsTxRef', null).then((opportunistObsTxRefKey) => {
            if (opportunistObsTxRefKey) {
              fieldRecordDetailsOnTheFlyFormCtrl.txRef = getDefaultOpportunistObsTxRef();
              return;
            }
          }*/
    
    fieldRecordDetailsContainerCtrl.initialFieldRecord = {
      txKey: fieldRecordDetailsContainerCtrl.txKey,
      txRefType: fieldRecordDetailsContainerCtrl.txRefKey,
      txRefVersion: fieldRecordDetailsContainerCtrl.txRefVersion,
      txRefFilter: fieldRecordDetailsContainerCtrl.txRefFilter,
      txGroup: fieldRecordDetailsContainerCtrl.txGroup,
      fieldSurveyId: fieldRecordDetailsContainerCtrl.fieldSurveyId,
      txHeadcount: fieldRecordDetailsContainerCtrl.txHeadcountStr
        ? Number(fieldRecordDetailsContainerCtrl.txHeadcountStr)
        : null,
      txHeadcountAccuracy: fieldRecordDetailsContainerCtrl.txHeadcountAccuracy,
    };

    fieldRecordDetailsContainerCtrl.mapTabFormData = {};
  } else {
    // - si modifications : initialisation avec les données issues de la base
    FieldRecordDetailsService.load(fieldRecordDetailsContainerCtrl.fieldRecordId);
  }

  function onLocalLayerChanged(layerId) {
    StudyMapService.saveLayerVisibility(fieldRecordDetailsContainerCtrl.studyId, layerId);
  }

  function isLoading() {
    if (isNew()) {
      if (!fieldRecordDetailsContainerCtrl.fieldSurveyId) {
        return !fieldRecordDetailsContainerCtrl.studyState || fieldRecordDetailsContainerCtrl.studyState.processing;
      }
      return (
        !fieldRecordDetailsContainerCtrl.studyState ||
        fieldRecordDetailsContainerCtrl.studyState.processing ||
        !fieldRecordDetailsContainerCtrl.fieldSurveyState ||
        fieldRecordDetailsContainerCtrl.fieldSurveyState.loading ||
        !fieldRecordDetailsContainerCtrl.extraTablesState ||
        fieldRecordDetailsContainerCtrl.extraTablesState.loading
      );
    }
    return (
      !fieldRecordDetailsContainerCtrl.studyState ||
      fieldRecordDetailsContainerCtrl.studyState.processing ||
      !fieldRecordDetailsContainerCtrl.fieldRecordState ||
      fieldRecordDetailsContainerCtrl.fieldRecordState.loading ||
      (fieldRecordDetailsContainerCtrl.fieldRecordState.fieldRecord &&
        fieldRecordDetailsContainerCtrl.fieldRecordState.fieldRecord.fieldSurvey &&
        (!fieldRecordDetailsContainerCtrl.extraTablesState || fieldRecordDetailsContainerCtrl.extraTablesState.loading))
    );
  }

  function isLoaded() {
    if (isNew()) {
      if (!fieldRecordDetailsContainerCtrl.fieldSurveyId) {
        return fieldRecordDetailsContainerCtrl.studyState && fieldRecordDetailsContainerCtrl.studyState.loaded;
      }
      return (
        fieldRecordDetailsContainerCtrl.studyState &&
        fieldRecordDetailsContainerCtrl.studyState.loaded &&
        fieldRecordDetailsContainerCtrl.fieldSurveyState &&
        fieldRecordDetailsContainerCtrl.fieldSurveyState.loaded &&
        fieldRecordDetailsContainerCtrl.extraTablesState &&
        fieldRecordDetailsContainerCtrl.extraTablesState.loaded
      );
    }
    return (
      fieldRecordDetailsContainerCtrl.studyState &&
      fieldRecordDetailsContainerCtrl.studyState.loaded &&
      fieldRecordDetailsContainerCtrl.fieldRecordState &&
      fieldRecordDetailsContainerCtrl.fieldRecordState.loaded &&
      fieldRecordDetailsContainerCtrl.fieldRecordState.fieldRecord &&
      (!fieldRecordDetailsContainerCtrl.fieldRecordState.fieldRecord.fieldSurvey ||
        (fieldRecordDetailsContainerCtrl.extraTablesState && fieldRecordDetailsContainerCtrl.extraTablesState.loaded))
    );
  }

  function isWebClient() {
    return !IS_CORDOVA;
  }

  function isNew() {
    return !fieldRecordDetailsContainerCtrl.fieldRecordId;
  }

  function isExtraTableValid(tableName) {
    return (
      fieldRecordDetailsContainerCtrl.extraTabsFormData[tableName] &&
      fieldRecordDetailsContainerCtrl.extraTabsFormData[tableName].formValid
    );
  }

  function canEdit() {
    if (isNew()) {
      return (
        fieldRecordDetailsContainerCtrl.studyState &&
        fieldRecordDetailsContainerCtrl.studyState.study &&
        fieldRecordDetailsContainerCtrl.studyState.study.canCreateSubElements
      );
    }
    return (
      fieldRecordDetailsContainerCtrl.fieldRecordState &&
      fieldRecordDetailsContainerCtrl.fieldRecordState.fieldRecord &&
      fieldRecordDetailsContainerCtrl.fieldRecordState.fieldRecord.canEdit
    );
  }

  function canEditMap() {
    if (isNew()) {
      return canEdit();
    }
    return (
      canEdit() &&
      fieldRecordDetailsContainerCtrl.fieldSurveyState &&
      fieldRecordDetailsContainerCtrl.fieldSurveyState.fieldSurvey &&
      fieldRecordDetailsContainerCtrl.fieldSurveyState.fieldSurvey.surveyType.protocol.key != 'RA'
    );
  }

  function canSubmit() {
    return (
      isLoaded() &&
      canEdit() &&
      !!fieldRecordDetailsContainerCtrl.mainTabFormData &&
      !!fieldRecordDetailsContainerCtrl.mapTabFormData &&
      (isNew() || !!fieldRecordDetailsContainerCtrl.mediaTabFormData) &&
      extraTablesLoaded()
    );
  }

  function extraTablesLoaded() {
    if (!fieldRecordDetailsContainerCtrl.extraTablesState) {
      // L'observation courante ne gère pas les extra tables
      return true;
    }

    return _.every(
      fieldRecordDetailsContainerCtrl.extraTablesState.extraTables,
      (extraTable) => !!fieldRecordDetailsContainerCtrl.extraTabsFormData[extraTable]
    );
  }

  function hasFieldSurvey() {
    if (fieldRecordDetailsContainerCtrl.fieldSurveyId) {
      // On a un fieldSurveyId : on est sur de créer dans un relevé
      return true;
    }
    // On regarde si on crée depuis une observation (créer et continuer) qui est dans un relevé ou non
    if (
      !fieldRecordDetailsContainerCtrl.fieldRecordState ||
      !fieldRecordDetailsContainerCtrl.fieldRecordState.fieldRecord
    ) {
      // On n'a pas d'informations sur l'observation : on attend
      return undefined;
    }
    // On regarde si l'observation est dans un relevé
    return !!fieldRecordDetailsContainerCtrl.fieldRecordState.fieldRecord.fieldSurvey;
  }

  function useExtraTable(extraTable) {
    if (!fieldRecordDetailsContainerCtrl.extraTablesState || fieldRecordDetailsContainerCtrl.extraTablesState.loading) {
      return false;
    }

    return _.includes(fieldRecordDetailsContainerCtrl.extraTablesState.extraTables, extraTable);
  }

  function getExtraTable(extraTable) {
    if (!useExtraTable(extraTable)) {
      return null;
    }

    if (
      !fieldRecordDetailsContainerCtrl.fieldRecordState ||
      !fieldRecordDetailsContainerCtrl.fieldRecordState.fieldRecord ||
      !fieldRecordDetailsContainerCtrl.fieldRecordState.fieldRecord.extraTables
    ) {
      if (fieldRecordDetailsContainerCtrl.extraTablesProvider()) {
        return fieldRecordDetailsContainerCtrl.extraTablesProvider()[extraTable];
      }

      return null;
    }

    return fieldRecordDetailsContainerCtrl.fieldRecordState.fieldRecord.extraTables[extraTable];
  }

  function reloadLayers() {
    fieldRecordDetailsContainerCtrl.layersState = StudyExpertLayerStore.getState();
  }

  function reloadStudyState() {
    fieldRecordDetailsContainerCtrl.studyState = StudyDetailsStore.getState();

    updateHeader();
    checkActiveTab();

    if (isNew() && isLoaded() && !canEdit()) {
      LxNotificationService.warning("Vous ne pouvez pas créer d'observation");
    }
  }

  function reloadFieldRecordState() {
    fieldRecordDetailsContainerCtrl.fieldRecordState = FieldRecordDetailsStore.getState();

    if (
      !isNew() &&
      fieldRecordDetailsContainerCtrl.fieldRecordState &&
      fieldRecordDetailsContainerCtrl.fieldRecordState.loaded &&
      !fieldRecordDetailsContainerCtrl.fieldRecordState.fieldRecord
    ) {
      if (fieldRecordDetailsContainerCtrl.fieldSurveyId) {
        $state.go('field-survey-field-records', {
          studyId: fieldRecordDetailsContainerCtrl.studyId,
          fieldSurveyId: fieldRecordDetailsContainerCtrl.fieldSurveyId,
        });
        return;
      }

      $state.go('study-field-records', { studyId: fieldRecordDetailsContainerCtrl.studyId });
      return;
    }

    // Initialisation des données cartos à partir des données du relevé
    if (!isNew()) {
      if (fieldRecordDetailsContainerCtrl.fieldRecordState.fieldRecord) {
        fieldRecordDetailsContainerCtrl.mapTabFormData = {
          txPosition: fieldRecordDetailsContainerCtrl.fieldRecordState.fieldRecord.txPosition,
          expertPosition: fieldRecordDetailsContainerCtrl.fieldRecordState.fieldRecord.expertPosition,
          expertPositionAccuracy: fieldRecordDetailsContainerCtrl.fieldRecordState.fieldRecord.expertPositionAccuracy,
        };

        fieldRecordDetailsContainerCtrl.isAutoLocated  = fieldRecordDetailsContainerCtrl.fieldRecordState.fieldRecord.txPosition?true:false;
        fieldRecordDetailsContainerCtrl.locationPrecision = fieldRecordDetailsContainerCtrl.fieldRecordState.fieldRecord.expertPositionAccuracy;


      } else {
        fieldRecordDetailsContainerCtrl.mapTabFormData = {};
      }
    }

    if (
      fieldRecordDetailsContainerCtrl.fieldRecordState &&
      fieldRecordDetailsContainerCtrl.fieldRecordState.fieldRecord
    ) {
      fieldRecordDetailsContainerCtrl.initialFieldRecord = fieldRecordDetailsContainerCtrl.fieldRecordState.fieldRecord;
    }
    if (
      fieldRecordDetailsContainerCtrl.fieldRecordState &&
      fieldRecordDetailsContainerCtrl.fieldRecordState.loaded &&
      fieldRecordDetailsContainerCtrl.fieldRecordState.fieldRecord
    ) {
      if (!fieldRecordDetailsContainerCtrl.fieldRecordState.fieldRecord.fieldSurvey) {
        updateHeader();
        return;
      }

      // On charge tout de même les données par rapport au relevé pour avoir plus d'informations sur les protocoles et les groupes taxonomiques liés
      if (!fieldRecordDetailsContainerCtrl.fieldSurveyState) {
        FieldSurveyDetailsService.load(
          fieldRecordDetailsContainerCtrl.fieldRecordState.fieldRecord.fieldSurvey.id,
          true
        );
      }

      if (!fieldRecordDetailsContainerCtrl.extraTablesState) {
        FieldRecordExtraTablesService.load(
          fieldRecordDetailsContainerCtrl.fieldRecordState.fieldRecord.fieldSurvey.id,
          fieldRecordDetailsContainerCtrl.fieldRecordState.fieldRecord.txGroup
        );
      }
    }

    updateHeader();
  }

  function reloadExtraTablesState() {
    fieldRecordDetailsContainerCtrl.extraTablesState = FieldRecordExtraTablesStore.getState();
  }

  function reloadFieldSurveyState() {
    fieldRecordDetailsContainerCtrl.fieldSurveyState = FieldSurveyDetailsStore.getState();

    if (!fieldRecordDetailsContainerCtrl.fieldSurveyState.loaded) {
      return;
    }

    if (
      fieldRecordDetailsContainerCtrl.fieldSurveyState.fieldSurvey &&
      'RP' === fieldRecordDetailsContainerCtrl.fieldSurveyState.fieldSurvey.surveyType.protocol.key
    ) {
      $state.go('field-survey-field-records-rp', {
        studyId: fieldRecordDetailsContainerCtrl.studyId,
        fieldSurveyId: fieldRecordDetailsContainerCtrl.fieldSurveyState.fieldSurvey.id,
      });
    }

    fieldRecordDetailsContainerCtrl.protocol = fieldRecordDetailsContainerCtrl.fieldSurveyId
      ? fieldRecordDetailsContainerCtrl.fieldSurveyState.fieldSurvey.surveyType.protocol.key
      : null;

    updateHeader();
    checkActiveTab();

    // Initialisation des données cartos
    // - si création dans un relevé : initialiser si la géométrie du relevé est un point
    // - sinon pas d'initialisation en création
    if (
      fieldRecordDetailsContainerCtrl.fieldSurveyState.fieldSurvey.geometry &&
      fieldRecordDetailsContainerCtrl.fieldSurveyState.fieldSurvey.geometry.type &&
      fieldRecordDetailsContainerCtrl.fieldSurveyState.fieldSurvey.geometry.type.toLocaleUpperCase('FR') === 'POINT'
    ) {
      _.defaults(fieldRecordDetailsContainerCtrl.mapTabFormData, {
        txPosition: fieldRecordDetailsContainerCtrl.fieldSurveyState.fieldSurvey.geometry,
        expertPosition: fieldRecordDetailsContainerCtrl.fieldSurveyState.fieldSurvey.geometry,
        expertPositionAccuracy: 0,
      });
    }
  }

  function updateHeader() {
    if (fieldRecordDetailsContainerCtrl.headerUpdated) {
      // Le header a déjà été initialisé
      return;
    }
    if (!isStudyAndFieldSurveyLoaded()) {
      return;
    }
    if (!isNew()) {
      // Cette observation n'est pas nouvelle
      if (
        !fieldRecordDetailsContainerCtrl.fieldRecordState ||
        !fieldRecordDetailsContainerCtrl.fieldRecordState.fieldRecord
      ) {
        // Les informations de l'observation n'ont toujours pas été récupérées
        return;
      }
      if (
        fieldRecordDetailsContainerCtrl.fieldRecordState.fieldRecord.fieldSurvey &&
        (!fieldRecordDetailsContainerCtrl.fieldSurveyState ||
          !fieldRecordDetailsContainerCtrl.fieldSurveyState.fieldSurvey)
      ) {
        // L'observation est liée à un relevé
        // on doit attendre d'avoir chargé les données relatives à ce relevé
        return;
      }
    }

    HeaderService.updateTitle(buildTitles());
    fieldRecordDetailsContainerCtrl.headerUpdated = true;
  }

  function isStudyAndFieldSurveyLoaded() {
    // l'étude n'est pas chargée
    if (!fieldRecordDetailsContainerCtrl.studyState || !fieldRecordDetailsContainerCtrl.studyState.study) {
      return false;
    }
    // on a un fieldSurveyId mais le relevé n'est pas chargé
    if (
      fieldRecordDetailsContainerCtrl.fieldSurveyId &&
      (!fieldRecordDetailsContainerCtrl.fieldSurveyState ||
        !fieldRecordDetailsContainerCtrl.fieldSurveyState.fieldSurvey)
    ) {
      return false;
    }
    return true;
  }

  function checkActiveTab() {
    if (fieldRecordDetailsContainerCtrl.activeTabChecked || !isStudyAndFieldSurveyLoaded()) {
      return;
    }
    if (!isNew()) {
      return;
    }

    if (
      fieldRecordDetailsContainerCtrl.creationMode === CREATION_MODE.DEFINE_ON_MAP ||
      _.includes(ACTIVATE_FIELD_RECORD_MAP_TAB, fieldRecordDetailsContainerCtrl.protocol)
    ) {
      fieldRecordDetailsContainerCtrl.activeTabChecked = true;
      fieldRecordDetailsContainerCtrl.activeTab = 1;
    }
  }

  function onMainTabChanged(mainTabFormData) {
    fieldRecordDetailsContainerCtrl.mainTabFormData = mainTabFormData;
  }

  function onMediaTabChanged(mediaTabFormData) {
    fieldRecordDetailsContainerCtrl.mediaTabFormData = mediaTabFormData;
  }

  function onSubmit(continueCreation = false) {

    if (
      isNew() &&
      fieldRecordDetailsContainerCtrl.creationMode != CREATION_MODE.WITHOUT_GEOMETRY &&
      (!fieldRecordDetailsContainerCtrl.mapTabFormData || !fieldRecordDetailsContainerCtrl.mapTabFormData.txPosition)
    ) {
      if (fieldRecordDetailsContainerCtrl.creationMode != CREATION_MODE.WITHOUT_GEOMETRY) {
        LxNotificationService.error(fieldRecordDetailsContainerCtrl.lockedTabErrors.map);
        return;
      }
    }
    if (fieldRecordDetailsContainerCtrl.lockedTabs.length) {
      fieldRecordDetailsContainerCtrl.lockedTabs.forEach((lockedTab) => {
        const error =
          fieldRecordDetailsContainerCtrl.lockedTabErrors[lockedTab] ||
          `L'observation ne peut être créée car l'onglet "${lockedTab.toUpperCase()}" n'a pas été validé.`;
        LxNotificationService.error(error);
      });
      return;
    }
    if (isNew()) {

      FieldRecordDetailsService.createFieldRecord(
        fieldRecordDetailsContainerCtrl.studyId,
        fieldRecordDetailsContainerCtrl.mainTabFormData.data,
        fieldRecordDetailsContainerCtrl.mapTabFormData,
        _.mapValues(fieldRecordDetailsContainerCtrl.extraTabsFormData, 'data'),
        fieldRecordDetailsContainerCtrl.fromState,
        fieldRecordDetailsContainerCtrl.fromParamsProvider(),
        continueCreation,
        $scope.fieldRecordForm
      );
      return;
    }
    FieldRecordDetailsService.updateFieldRecord(
      fieldRecordDetailsContainerCtrl.fieldRecordId,
      fieldRecordDetailsContainerCtrl.mainTabFormData.data,
      fieldRecordDetailsContainerCtrl.mapTabFormData,
      fieldRecordDetailsContainerCtrl.mediaTabFormData.data,
      _.mapValues(fieldRecordDetailsContainerCtrl.extraTabsFormData, 'data'),
      $scope.fieldRecordForm,
      IS_CORDOVA
    );
  }

  function onUploadMedia(input) {
    MediaService.uploadMedia(input);
  }

  function buildTitles() {
    var titles = [
      {
        label: fieldRecordDetailsContainerCtrl.studyState.study.key,
        link: {
          sref: 'study-details',
          params: { studyId: fieldRecordDetailsContainerCtrl.studyId },
        },
      },
    ];

    if (!isNew() && fieldRecordDetailsContainerCtrl.fieldRecordState.fieldRecord) {
      if (fieldRecordDetailsContainerCtrl.fieldRecordState.fieldRecord.fieldSurvey) {
        titles.push({
          label: fieldRecordDetailsContainerCtrl.fieldRecordState.fieldRecord.fieldSurvey.name,
          link: {
            sref: 'field-survey-field-records',
            params: {
              studyId: fieldRecordDetailsContainerCtrl.studyId,
              fieldSurveyId: fieldRecordDetailsContainerCtrl.fieldRecordState.fieldRecord.fieldSurvey.id,
            },
          },
        });
      }

      let formattedTxTime = $filter('tzDate')(
        fieldRecordDetailsContainerCtrl.fieldRecordState.fieldRecord.txTime * 1000,
        'DD/MM/YYYY',
        fieldRecordDetailsContainerCtrl.studyState.study.timezone
      );
      titles.push({
        label: `Observation de ${fieldRecordDetailsContainerCtrl.fieldRecordState.fieldRecord.txName} (${formattedTxTime})`,
        link: null,
      });
    } else if (fieldRecordDetailsContainerCtrl.fieldSurveyId) {
      titles = titles.concat([
        {
          label: fieldRecordDetailsContainerCtrl.fieldSurveyState.fieldSurvey.name,
          link: {
            sref: 'field-survey-field-records',
            params: {
              studyId: fieldRecordDetailsContainerCtrl.studyId,
              fieldSurveyId: fieldRecordDetailsContainerCtrl.fieldSurveyId,
            },
          },
        },
        {
          label: "Création d'une observation",
          link: null,
        },
      ]);
    } else {
      titles.push({
        label: "Création d'une observation à la volée",
        link: null,
      });
    }

    return titles;
  }

  function onDeleteFieldRecord() {
    if (isNew()) {
      // Ne devrait pas arriver
      return;
    }
    LxNotificationService.confirm(
      'Attention',
      "Êtes-vous sur de vouloir supprimer l'observation ?",
      { cancel: 'Non', ok: 'Oui' },
      (answer) => answer && FieldRecordDetailsService.deleteFieldRecord(fieldRecordDetailsContainerCtrl.fieldRecordId)
      /* (answer) => {
        if (!answer) {
          return;
        }
        FieldRecordRepository.deleteFieldRecord(fieldRecordDetailsContainerCtrl.fieldRecordState.fieldRecord.id).then(
          (fieldRecord) => {
            LxNotificationService.success('Observation supprimée');
            $state.go('field-survey-field-records', {
              studyId: fieldRecordDetailsContainerCtrl.studyId,
              fieldSurveyId: fieldRecordDetailsContainerCtrl.fieldRecordState.fieldRecord.fieldSurvey.id,
            });
          },
          (error) => {
            LxNotificationService.error(
              "Une erreur est survenue à la suppression de l'observation. " + (error.data || '')
            );
            $state.go('field-survey-field-records', {
              studyId: fieldRecordDetailsContainerCtrl.studyId,
              fieldSurveyId: fieldRecordDetailsContainerCtrl.fieldRecordState.fieldRecord.fieldSurvey.id,
            });
          }
        );
      } */
    );
  }

  function onLockedTabChange(tabName, isLocked) {
    if (isLocked && fieldRecordDetailsContainerCtrl.lockedTabs.indexOf(tabName) < 0) {
      fieldRecordDetailsContainerCtrl.lockedTabs.push(tabName);
    } else if (!isLocked) {
      const i = fieldRecordDetailsContainerCtrl.lockedTabs.indexOf(tabName);
      if (i > -1) {
        fieldRecordDetailsContainerCtrl.lockedTabs.splice(i, 1);
      }
    }
  }


  function onPositionChange(txPosition, expertPosition, expertPositionAccuracy, goToFirstTab) {
    fieldRecordDetailsContainerCtrl.mapTabFormData = { txPosition, expertPosition, expertPositionAccuracy };

    fieldRecordDetailsContainerCtrl.isAutoLocated  = txPosition?true:false;

    fieldRecordDetailsContainerCtrl.locationPrecision = expertPositionAccuracy;
    if (
      fieldRecordDetailsContainerCtrl.creationMode === CREATION_MODE.DEFINE_ON_MAP &&
      fieldRecordDetailsContainerCtrl.txKey &&
      goToFirstTab
    ) {
      onSubmit();
      return;
    }
    if (goToFirstTab) {
      fieldRecordDetailsContainerCtrl.activeTab = 0;
    }
  }

  function onETTabChanged(extraTableName, tabFormData) {
    fieldRecordDetailsContainerCtrl.extraTabsFormData[extraTableName] = tabFormData;
  }

  function onCreateAndContinueClicked() {
    onSubmit(true);
  }

  function isAutoLocatedValid() {
    return fieldRecordDetailsContainerCtrl.isAutoLocated && fieldRecordDetailsContainerCtrl.locationPrecision !== null;
  }

  function isAutoLocatedError() {
    return fieldRecordDetailsContainerCtrl.isAutoLocated && fieldRecordDetailsContainerCtrl.locationPrecision === null;
  }
}
